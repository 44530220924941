// import * as d3 from "d3";
import { select } from "d3-selection";
import * as L from "leaflet/src/Leaflet";
import Locate from "leaflet.locatecontrol";
// import {MaptilerLayer} from "@maptiler/leaflet-maptilersdk";
import {debounce} from "./utils";
import {layout, layers, map} from "./common";
import {updatePositions, displayForecastDetails, updateParticlesBBox} from "./data";
import {config} from "./config";
import {patchLeafletBounds} from "./patched-leaflet-bounds";
import {refreshDataLayers} from "./timeline";

export function initMap() {
  // console.time('initMap');
  map.instance = L.map('map', {
    center: config.mapCenter,
    zoom: config.mapZoom,
    minZoom: 9.15,
    maxBoundsViscosity: 1,
    zoomControl: false,
    zoomSnap: 0,
    zoomAnimation: false
  });

  L.control.zoom({position: 'topleft'}).addTo(map.instance);
  new Locate({
    position: 'bottomleft',
    keepCurrentZoomLevel: true,
    setView: false,
    strings: {
      title: "Voir ma position",
      metersUnit: "mètres",
      feetUnit: "pieds",
      popup: "Vous êtes situé(e) dans un périmètre de {distance} {unit} autour de ce point",
      outsideMapBoundsMsg: "Vous semblez être localisé(e) hors du périmètre de la carte"
    },
    locateOptions: {
      enableHighAccuracy: true
    }
  }).addTo(map.instance);
  patchLeafletBounds(L.Bounds);

  L.svg().addTo(map.instance);
  document.querySelector("#map .leaflet-overlay-pane")
    .insertAdjacentHTML('afterbegin', '<div id="particles_wrapper" class="leaflet-zoom-animated" style="width: ' + layout.width + 'px; height: ' + layout.height + 'px;"></div>');

  let isSingleClick = true, updateParticles = debounce(updateParticlesBBox, 500);
  map.instance.on("zoomstart", function() {
    stopParticles();
  });
  map.instance.on("zoomend", function(evt) {
    updatePositions();
    setTimeout(function() {
      isSingleClick = true;
    }, 300);
    if ((map.windLayer && layers.dataLayers.indexOf('wind_fc') !== -1) ||
      (map.wavesLayer && layers.dataLayers.indexOf('waves_fc') !== -1)) {
      updateParticles();
    }
  });
  map.instance.on("movestart", function() {
    stopParticles();
  });
  map.instance.on("moveend", function(evt) {
    updatePositions();
    if ((map.windLayer && layers.dataLayers.indexOf('wind_fc') !== -1) ||
      (map.wavesLayer && layers.dataLayers.indexOf('waves_fc') !== -1)) {
      updateParticles();
    }
    updateParticlesPane();
  });
  map.instance.on('click', function (e) {
    setTimeout(function() {
      if (isSingleClick) {
        if (layers.dataLayers.indexOf('wind_fc') !== -1 || layers.dataLayers.indexOf('waves_fc') !== -1) {
          let popLocation = e.latlng;
          map.popup = L.popup().setLatLng(popLocation);
          config.popup = map.popup;
          setPopupContent(config.popup, e);
          map.instance.openPopup(config.popup);
        }
      }
    }, 300);
  });
  map.instance.on('dblclick', function(e) {
    isSingleClick = false;
  });
  map.instance.on('popupclose', function(e) {
    map.popup = null;
  });
  map.instance.on('locationerror', function (e) {
    window.alert("Votre position n'a pas pu être relevée.")
  });

  layers.overlay = select(map.instance.getPanes().overlayPane).select("svg").attr("id", "markers_layer")
    .attr("pointer-events", "all");

  return displayCoastLine(true).then(function() {
    console.log('maxBounds: ' + config.mapBounds);
    map.instance.setMaxBounds(config.mapBounds);
    map.viewBox = map.instance.getPixelBounds();
    map.refZoom = map.instance.getBoundsZoom(config.mapBounds);
    map.windBox = [map.instance.project(config.mapBounds[0], map.refZoom), map.instance.project(config.mapBounds[1], map.refZoom)];
    map.windWidth = map.windBox[1].x - map.windBox[0].x;
    map.windHeight = map.windBox[1].y - map.windBox[0].y;
    map.wavesBox = [map.instance.project(config.mapBounds[0], map.refZoom), map.instance.project(config.mapBounds[1], map.refZoom)];
    map.wavesWidth = map.wavesBox[1].x - map.wavesBox[0].x;
    map.wavesHeight = map.wavesBox[1].y - map.wavesBox[0].y;
    // console.timeEnd('initMap');
  });
}

function updateParticlesPane() {
  let panTransform = document.querySelector("#map > .leaflet-map-pane").getAttribute('style');
  if (panTransform && panTransform.indexOf('translate3d') !== -1) {
    let [x, y, z] = panTransform.match(/-?[.\d]+px/g).map(function(v) { return parseInt(v.replace('px', '')); });
    document.querySelector("#particles_wrapper").setAttribute('style',
      'width: ' + layout.width + 'px; height: ' + layout.height + 'px; transform: translate3d(' + (-x) + 'px, ' + (-y) + 'px, 0px);');
    if (document.querySelector("#waves_wrapper")) {
      document.querySelector("#waves_wrapper").setAttribute('style',
        'width: ' + layout.width + 'px; height: ' + layout.height + 'px; transform: translate3d(' + (-x) + 'px, ' + (-y) + 'px, 0px);');
    }
  }
}

function stopParticles() {
  if (map.windLayer && layers.dataLayers.indexOf('wind_fc') !== -1) {
    map.windLayer.stop();
  }

  if (map.wavesLayer && layers.dataLayers.indexOf('waves_fc') !== -1) {
    map.wavesLayer.stop();
  }
}

export function toggleMap(btn, ref) {
  if (map.activeMap !== ref) {
    let activeBtn = document.querySelector("#side_menu .map_layer.active");
    if (activeBtn) {
      activeBtn.classList.remove('active');
    }
    displayMapRef(ref);
    map.activeMap = ref;
    btn.classList.add('active');
  }
}

export function displayMapRef(ref, skipRefresh) {
  let mapContainer = document.querySelector("#map_container");

  if (ref === 'wm') {
    clearBgLayers();
    displayCoastLine(true);
  } else if (ref === 'satellite') {
    displayOrthoLayer();
  } else if (ref === 'elevation') {
    displayMntLayer();
  }
  mapContainer.classList.remove(map.activeMap);
  mapContainer.classList.add(ref);
  if (typeof(skipRefresh) === 'undefined' || !skipRefresh) {
    refreshDataLayers();
  }
}

export function displayCoastLine(forceFill, waves = false) {
  // console.time('displayCoastLine');
  // const mtLayer = new MaptilerLayer({
  //   apiKey: "ZkJJ9tNsbzF6IRldrpeM",
  //   style: waves ? 'https://api.maptiler.com/maps/dc27b623-f58d-4a84-a9eb-c8f5e970c2e7/style.json' : (forceFill ? "https://api.maptiler.com/maps/9252a2f8-bdda-477b-aa0a-52eeb8a1653c/style.json" : "https://api.maptiler.com/maps/5ddfa15f-9859-4f36-95f7-25323cd9b3c3/style.json"),
  //   attributionControl: false,
  //   pane: 'overlayPane'
  // }).addTo(map.instance);

  const rasterTilesUrl =  waves ? 'https://api.maptiler.com/maps/dc27b623-f58d-4a84-a9eb-c8f5e970c2e7/{z}/{x}/{y}.png?key=UQGXcEq7nYklZxftO9vu' : (forceFill ? "https://tiles.windmorbihan.com/{z}/{x}/{y}.png" : "https://api.maptiler.com/maps/5ddfa15f-9859-4f36-95f7-25323cd9b3c3/{z}/{x}/{y}.png?key=UQGXcEq7nYklZxftO9vu");
  const mtLayer = L.tileLayer(rasterTilesUrl, {
    tileSize: 512,
    zoomOffset: -1,
    minZoom: 1,
    attribution: false,
    crossOrigin: true
  }).addTo(map.instance);

  layers.bgLayers.push(mtLayer);
  // console.timeEnd('displayCoastLine');
  return Promise.resolve(mtLayer);
}

export function clearBgLayers() {
  let l;
  for (l of layers.bgLayers) {
    map.instance.removeLayer(l);
  }
  layers.bgLayers = [];
}

function setPopupContent(popup, evt) {
  console.debug('pt clicked : ' + evt.latlng);
  if (layers.dataLayers.indexOf('wind_fc') !== -1 || layers.dataLayers.indexOf('waves_fc') !== -1) {
    return displayForecastDetails(popup, evt.latlng.lat, evt.latlng.lng);
  } else {
    return popup.setContent(evt.latlng.lat + ', ' + evt.latlng.lng);
  }
}

function displayOrthoLayer() {
  let mtLayer = new L.tileLayer("https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal" +
    "&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}");
  // for (let l of layers.bgLayers) {
  //   if (l.setStyle) {
  //     l.setStyle('https://api.maptiler.com/maps/d277497d-3c32-4fde-bab0-d5de1d0b9abc/style.json?key=UQGXcEq7nYklZxftO9vu');
  //     mtLayer = l;
  //   }
  // }
  mtLayer.addTo(map.instance);
  layers.bgLayers.push(mtLayer);
}

function displayMntLayer() {
  let layr = new L.tileLayer.wms("https://services.data.shom.fr/INSPIRE/wms/r?", {
    layers: 'MNT_COTIER_MORBIHAN_TANDEM_20m_PBMA_3857_WMSR',
    format: 'image/png',
    transparent: true,
    version: '1.3.0',
    service: 'WMS',
    request: 'GetMap',
    uppercase: true,
    className: 'mnt_layer'
  });
  layr.addTo(map.instance);
  layers.bgLayers.push(layr);
}

export function projectInMap(lng, lat) {
  let pt = map.instance.latLngToLayerPoint([lat, lng]);
  return [pt.x, pt.y];
}
